import React from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import { Link } from 'gatsby'
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';

const Navigation = () => {
  return (
    // <Navbar
    //   collapseOnSelect
    //   expand="sm"
    //   bg="bg-white"
    //   variant="light"
    //   style={{
    //     boxShadow: '0px 4px 17px -4px rgba(0,0,0,0.13)',
    //     backgroundColor: 'white',
    //     padding: 20
    //   }}
    // >
    //   <Container>
    //     <Navbar.Brand><Link to='/' style={{fontWeight: 900}}>Quinston</Link></Navbar.Brand>
    //     <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    //     <Navbar.Collapse id="responsive-navbar-nav">
    //       <Nav className="mr-auto" style={{ margin: '0 auto' }}>
    //         <Nav.Link style={{ marginLeft: '1rem', marginRight: '1rem' }}>
    //           <small style={{ fontWeight: 400 }}>
    //             <Link to="/books"><Button variant="light">Books</Button></Link>
    //           </small>
    //         </Nav.Link>
    //         <Nav.Link style={{ marginLeft: '1rem', marginRight: '1rem' }}>
    //           <small style={{ fontWeight: 400 }}>
    //             <Link to="/products"><Button variant="light">Products</Button></Link>
    //           </small>
    //         </Nav.Link>
    //         <Nav.Link style={{ marginLeft: '1rem', marginRight: '1rem' }}>
    //           <small style={{ fontWeight: 400 }}>
    //             <Link to="/blog"><Button variant="light">Blog</Button></Link>
    //           </small>
    //         </Nav.Link>
    //         <Nav.Link style={{ marginLeft: '1rem', marginRight: '1rem' }}>
    //           <small style={{ fontWeight: 400 }}>
    //             <Link to="/code-snippets"><Button variant="light">Code Snippets</Button></Link>
    //           </small>
    //         </Nav.Link>
    //       </Nav>
    //       {/* <Nav>
    //         <Nav.Link style={{ marginRight: '1rem' }}>
    //           <small style={{ fontWeight: 900 }}>
    //             <Link to="/contribute">Contribute</Link>
    //           </small>
    //         </Nav.Link>
    //       </Nav> */}
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar>
    <Navbar expand="lg" style={{ backgroundColor: 'white' }}>
      <Container>
        <Navbar.Brand><Link></Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Navbar.Text>
              <Link to="/">Quinston</Link>
            </Navbar.Text>
            {/* <Nav.Link href="#link">Link</Nav.Link>
            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          <Nav className="right">
            {/* <Navbar.Text style={{marginLeft: 10, marginRight: 10}}>
              <Link to="/books">Books</Link>
            </Navbar.Text> */}
            <Navbar.Text style={{marginLeft: 10, marginRight: 10}}>
              <Link to="/micro-saas-side-hustle">How to Micro-SaaS side-hustle</Link>
            </Navbar.Text>
            <Navbar.Text style={{marginLeft: 10, marginRight: 10}}>
              <Link to="/products">Products</Link>
            </Navbar.Text>
            <Navbar.Text style={{marginLeft: 10, marginRight: 10}}>
              <Link to="/code-snippets">Code Snippets</Link>
            </Navbar.Text>
            <Navbar.Text style={{marginLeft: 10, marginRight: 10}}>
              <Link to="/blog">Blog</Link>
            </Navbar.Text>
          </Nav>
          {/* <Form inline>
            <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            <Button variant="outline-success">Search</Button>
          </Form> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Navigation
