import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Navigation from "../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../components/donation-footer";
import axios from "axios";
import AdBanner from "../components/ad-banner";
import Gist from "../components/gist";
import CodeSnippetHero from "./code-snippet-hero";

// <iframe width="560" height="315" src="https://www.youtube.com/embed/LpJceTbzJFo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

class CodeSnippet extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
	}

	render() {
		return (
			<Layout>

				<SEO
					title={this.props.header}
					keywords={this.props.keywords}
					description={this.props.description}
				/>

				{/* <div style={{ marginTop: 30 }}></div> */}
				{/* <AdBanner></AdBanner> */}
				{/* <div style={{ marginTop: 30 }}></div> */}

				{/* <Container>
					<div style={{ marginTop: "2rem" }} />
					<Row>
						<Col>
							<Link to="/code-snippets/"><Button variant="light">⬅ Back</Button></Link>
						</Col>
					</Row>
				</Container> */}

				<CodeSnippetHero
					header={this.props.header}
					subHeader={this.props.subHeader}
					maxWidth={650}
				/>

				{(this.props.description.length > 0)
					?
					<Container>
						<Row style={{
							display: "flex",
							justifyContent: "center",
							marginBottom: '1.45rem'
						}}>
							<Col style={{ maxWidth: 650 }}>
								<p>{this.props.description}</p>
							</Col>
						</Row>
					</Container>
					:
					<div></div>
				}

				{(this.props.embeddedVideo.length > 0)
					?
					<Container>
						<Row style={{
							display: "flex",
							justifyContent: "center",
							marginBottom: '4rem'
						}}>
							<Col style={{ maxWidth: 650 }}>
								<div className="embed-responsive embed-responsive-16by9">
									<iframe className="embed-responsive-item" src={`https://www.youtube.com/embed/${this.props.embeddedVideo}`}allowfullscreen></iframe>
								</div>
							</Col>
						</Row>
					</Container>
					:
					null
				}


				<Container>
					<Row style={{
						display: "flex",
						justifyContent: "center"
					}}>
						<Col style={{ maxWidth: 650 }}>
							<Gist url={this.props.embeddedCode}></Gist>
						</Col>
					</Row>
				</Container>

				<div style={{ marginTop: 20 }} />

				<Container>
					<Row style={{
						display: "flex",
						justifyContent: "center"
					}}>
						<Col style={{ maxWidth: 650 }}>
							<a href={this.props.embeddedCode} target="_blank"><Button variant="dark" block>View Raw</Button></a>
						</Col>
					</Row>
				</Container>

				<div style={{ marginTop: 100 }} />
				<DonationFooter ad={false} />

			</Layout>
		);
	}
}

CodeSnippet.propTypes = {};

export default CodeSnippet;
